import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Contact.css';
import './Mobile.css';

import sarah1 from './contact/sarah1.jpg';

class Contact extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="app-container">
        <div className="header-container">
          <div className="header-left">
            <Link to="/">
              <div className="header-left-name">Sarah Mosley</div>
            </Link>
          </div>
          <div className="header-right">
            <div className="header-right-flex">
              <div className="header-right-link">Design</div>
              <Link to="/collage">
                <div className="header-right-link">Collage</div>
              </Link>
              <Link to="/oil">
                <div className="header-right-link">Oil</div>
              </Link>
              <div className="header-right-link">Editorial</div>
              <Link to="/contact">
                <div className="header-right-link header-active-link">Contact</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="contact-container">
          <img src={sarah1} className="headshot-image" />
          <div className="contact-item"><a href="mailto:sarahmosley@berkeley.edu">sarahmosley@berkeley.edu</a></div>
        </div>
      </div>
    );
  }

}

export default Contact;
