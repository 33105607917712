import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Collages.css';
import './Mobile.css';

import collage1 from './collages/collage1.jpg';
import collage2 from './collages/collage2.jpg';
import collage3 from './collages/collage3.jpg';
import collage4 from './collages/collage4.jpg';
import collage5 from './collages/collage5.jpg';

class Collages extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="app-container">
        <div className="header-container">
          <div className="header-left">
            <Link to="/">
              <div className="header-left-name">Sarah Mosley</div>
            </Link>
          </div>
          <div className="header-right">
            <div className="header-right-flex">
              <div className="header-right-link">Design</div>
              <Link to="/collage">
                <div className="header-right-link header-active-link">Collage</div>
              </Link>
              <Link to="/oil">
                <div className="header-right-link">Oil</div>
              </Link>
              <div className="header-right-link">Editorial</div>
              <Link to="/contact">
                <div className="header-right-link">Contact</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="collages-container">
          <div className="collage-section collage-section-1">
            <img src={collage1} className="collage-image" />
            <div className="collage-title"><i>Wolf Alice Emissary</i></div>
          </div>
          <div className="collage-section collage-section-2">
            <img src={collage2} className="collage-image" />
            <div className="collage-title"><i>Bun's Michelin Salad</i></div>
          </div>
          <div className="collage-section collage-section-3">
            <img src={collage3} className="collage-image" />
            <div className="collage-title"><i>Ghosts of Stockton & Columbus</i></div>
          </div>
          <div className="collage-section collage-section-4">
            <img src={collage4} className="collage-image" />
            <div className="collage-title"><i>Serena's Tennis Court Oath</i></div>
          </div>
          <div className="collage-section collage-section-5">
            <img src={collage5} className="collage-image" />
            <div className="collage-title"><i>Astro Announcement</i></div>
          </div>
        </div>
      </div>
    );
  }

}

export default Collages;
