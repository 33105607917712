import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from './Home.js';
import Collages from './Collages.js';
import Oil from './Oil.js';
import Contact from './Contact.js';

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collage" element={<Collages />} />
          <Route path="/oil" element={<Oil />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    )
  }
}

export default App;