import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Home.css';
import './Mobile.css';

import ffhp1 from './photography/ffhp1.jpg';
import ffhp2 from './photography/ffhp2.jpg';
import ffhp3 from './photography/ffhp3.jpg';
import ffhp4 from './photography/ffhp4.jpg';
import ba1 from './photography/bayarea1.jpeg';
import ba2 from './photography/bayarea2.jpeg';
import ba3 from './photography/bayarea3.jpeg';
import ba4 from './photography/bayarea4.jpeg';
import ba5 from './photography/bayarea5.jpg';
import marfa1 from './photography/marfa1.jpeg';
import marfa2 from './photography/marfa2.jpeg';
import marfa3 from './photography/marfa3.jpeg';
import marfa4 from './photography/marfa4.jpeg';
import la1 from './photography/la1.jpeg'; // WHERE?
import la2 from './photography/la2.jpeg';
import euro1 from './photography/euro1.jpeg';
import euro2 from './photography/euro2.jpeg';
import euro3 from './photography/euro3.jpeg';
import euro4 from './photography/euro4.jpeg';
import nyc1 from './photography/nyc1.jpeg'; // WHERE?
import nyc2 from './photography/nyc2.jpeg';// WHERE?
import nyc3 from './photography/nyc3.jpeg';// WHERE?
import nyc4 from './photography/nyc4.jpeg';
import lakeside1 from './photography/lakeside1.jpeg';
import lakeside2 from './photography/lakeside2.jpeg';
import lakeside3 from './photography/lakeside3.jpeg';
import lakeside4 from './photography/lakeside4.jpeg';
import lakeside5 from './photography/lakeside5.jpeg';
import sc1 from './photography/strawberry1.jpeg';
import sc2 from './photography/strawberry2.jpeg';
import sc3 from './photography/strawberry3.jpeg';
import tile1 from './photography/tiles1.jpeg';
import tile2 from './photography/tiles2.JPG';
import tile3 from './photography/tiles3.jpeg';
import tile4 from './photography/tiles4.jpeg';
import tile5 from './photography/tiles5.jpeg';
import tile6 from './photography/tiles6.jpeg';
import tile7 from './photography/tiles7.jpeg';
import film1 from './photography/film1.jpg';
import film2 from './photography/film2.jpg';
import film3 from './photography/film3.jpg';
import film4 from './photography/film4.jpg';
import film5 from './photography/film5.jpg';
import film6 from './photography/film6.jpg';
import film7 from './photography/film7.jpg';
// import iphone1 from './photography/iphone1.jpg';
// import iphone2 from './photography/iphone2.jpeg';
// import iphone3 from './photography/iphone3.jpeg';
// import iphone4 from './photography/iphone4.jpeg';

const DISTANCE_BEFORE_FIRST_IMAGE = 0;
const DISTANCE_BETWEEN_IMAGES = 20;

const BUTTONS = [
  {
    name: '"Friends From High Places"',
    images: [ffhp1, ffhp2, ffhp3, ffhp4]
  },
  {
    name: 'Bay Area',
    images: [ba1, ba2, ba3, ba4, ba5]
  },
  {
    name: 'Marfa',
    images: [marfa1, marfa2, marfa3, marfa4]
  },
  {
    name: 'LA',
    images: [la1, la2]
  },
  {
    name: 'Euro',
    images: [euro1, euro2, euro3, euro4]
  },
  {
    name: 'NYC',
    images: [nyc1, nyc2, nyc3, nyc4]
  },
  {
    name: 'Lakeside',
    images: [lakeside1, lakeside2, lakeside3, lakeside4, lakeside5]
  },
  {
    name: 'Strawberry Canyon',
    images: [sc1, sc2, sc3]
  },
  {
    name: 'Tiles',
    images: [tile1, tile2, tile3, tile4, tile5, tile6, tile7]
  },
  {
    name: 'Film',
    images: [film1, film2, film3, film4, film5, film6, film7]
  }
]

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enter_locations: [],
      added_images: [],
      image_layers: [],
      image_counter: 0
    };
  }

  hoverListItem(e, button) {
    if (!this.state.enter_locations[button]) {
      let new_enter_locations = this.state.enter_locations;
      new_enter_locations[button] = [e.clientX, e.clientY];
      this.setState({
        enter_locations: new_enter_locations
      })
    } else {
      if (getDistance(e.clientX, e.clientY, this.state.enter_locations[button][0], this.state.enter_locations[button][1]) > (this.state.added_images[button] ? DISTANCE_BETWEEN_IMAGES : DISTANCE_BEFORE_FIRST_IMAGE)) {
        if (this.state.added_images[button]?.length === BUTTONS[button].images.length) {
          let new_added_images = this.state.added_images;
          let new_image_layers = this.state.image_layers;
          let new_enter_locations = this.state.enter_locations;
          // Get the image that is lowest down in stack and replace its coords and stack position
          let local_bottom_image_position = 99999999;
          let local_bottom_image_index = null;
          for (var i = 0; i < this.state.added_images[button].length; i++) {
            if (this.state.image_layers[button][i] < local_bottom_image_position) {
              local_bottom_image_position = this.state.image_layers[button][i];
              local_bottom_image_index = i;
            }
          }
          new_added_images[button][local_bottom_image_index] = [e.clientX, e.clientY];
          new_enter_locations[button] = [e.clientX, e.clientY];
          new_image_layers[button][local_bottom_image_index] = this.state.image_counter;
          this.setState({
            added_images: new_added_images,
            image_layers: new_image_layers,
            enter_locations: new_enter_locations,
            image_counter: this.state.image_counter + 1
          })
        } else {
          let new_added_images = this.state.added_images;
          let new_image_layers = this.state.image_layers;
          let new_enter_locations = this.state.enter_locations;
          if (!new_added_images[button]) {
            new_added_images[button] = [];
          }
          if (!new_image_layers[button]) {
            new_image_layers[button] = [];
          }
          new_added_images[button].push([e.clientX, e.clientY]);
          new_enter_locations[button] = [e.clientX, e.clientY];
          new_image_layers[button].push(this.state.image_counter);
          this.setState({
            added_images: new_added_images,
            image_layers: new_image_layers,
            enter_locations: new_enter_locations,
            image_counter: this.state.image_counter + 1
          })
        }
        // if (this.state.image_counter > 5) {
        //   let bottom_image_position = 99999999;
        //   let bottom_image_index = null;
        //   let bottom_image_button = null;
        //   for (var b = 0; b < BUTTONS.length; b++) {
        //     for (var t = 0; t < this.state.image_layers[b]?.length; t++) {
        //       if (this.state.image_layers[b][t] < bottom_image_position) {
        //         bottom_image_position = this.state.image_layers[b][t];
        //         bottom_image_index = t;
        //         bottom_image_button = b;
        //       }
        //     }
        //   }
        //   let new_added_images = this.state.added_images;
        //   let new_image_layers = this.state.image_layers;
        //   new_added_images[bottom_image_button].splice(bottom_image_index, 1);
        //   new_image_layers[bottom_image_button].splice(bottom_image_index, 1);
        //   console.log(bottom_image_button + " hello " + bottom_image_index);
        //   this.setState({
        //     image_layers: new_image_layers,
        //     added_images: new_added_images,
        //     image_counter: this.state.image_counter - 1
        //   })
        // }
      }
    }
  }



  render() {
    return (
      <div className="app-container">
        <div className="header-container">
          <div className="header-left">
            <Link to="/">
              <div className="header-left-name">Sarah Mosley</div>
            </Link>
          </div>
          <div className="header-right">
            <div className="header-right-flex">
              <div className="header-right-link">Design</div>
              <Link to="/collage" className="header-right-link">
                <div>Collage</div>
              </Link>
              <Link to="/oil" className="header-right-link">
                <div>Oil</div>
              </Link>
              <div className="header-right-link">Editorial</div>
              <Link to="/contact" className="header-right-link">
                <div>Contact</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="main-container">
          <div className="main-list">
            {/* This adds .item-with-image class if the item's image is on top of the stack */}
            {BUTTONS.map((button, index) => {
              let is_top_of_stack = false;
              if (this.state.added_images[index]) {
                for (var i = 0; i < this.state.added_images[index].length; i++) {
                  if (this.state.image_layers[index][i] === this.state.image_counter - 1) {
                    is_top_of_stack = true;
                  }
                }
              }
              return (
                <div className={is_top_of_stack ? "main-list-item item-with-image" : "main-list-item"} onMouseMove={(e) => this.hoverListItem(e, index)} onClick={() => this.clickListItem(index)}>{button.name}</div>
              )
            })}
          </div>
        </div>
        <div className="background-container">
          <div className="background-list">
            {BUTTONS.map((button, index) => {
              return (
                <div className="background-list-item">{button.name}</div>
              )
            })}
          </div>
        </div>
        <div className="hover-images-container">
          {/* The zIndex here was the total number of added images when current image was added */}
          {BUTTONS.map((button, index) => {
            return button.images.map((image, image_index) => {
              if (this.state.added_images[index] && this.state.added_images[index][image_index]) {
                return (
                  <img style={{zIndex: this.state.image_layers[index][image_index], left: this.state.added_images[index][image_index][0], top: this.state.added_images[index][image_index][1]}} className="hover-image" src={image} />
                )
              }
            })
          })}
        </div>
        <div className="images-preload">
          {BUTTONS.map((button, index) => {
            return button.images.map((image, image_index) => {
              return (
                <img className="preload-image" src={image} />
              )
            })
          })}
        </div>
      </div>
    );
  }

}

function getDistance(x1, y1, x2, y2) {
  let y = x2 - x1;
  let x = y2 - y1;
  return Math.sqrt(x * x + y * y);
}

export default Home;
